import fetchJson from "@/lib/fetchJson";
import Session from "supertokens-web-js/recipe/session";
import useSWR from "swr";

export const useUsers = () => useSWR<User[]>(`/users`, fetchJson);

export const deleteUser = async (id: string) => {
  await fetchJson(`/users/${id}`, {
    method: "DELETE",
  });
};

export const impersonateUser = async (email: string) => {
  await fetchJson(`/users/impersonate?email=${email}`);
};

export const updateAvatar = async (formData: FormData) =>
  fetch(`/backend/users/changeAvatar`, {
    method: "POST",
    body: formData,
    headers: {},
  });

export const updateTheme = (theme: string) =>
  fetchJson(`/theme`, {
    method: "POST",
    body: JSON.stringify({ theme }),
  });

export const logoutUser = async () =>
  fetchJson("/auth/logout").then(async () => {
    console.log("signing out");
    await Session.signOut();
  });
