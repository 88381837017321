import useUser from "@/lib/useUser";
import Link from "next/link";
import { sidebarLinks } from "./links";
import { logout } from "./common";
import { IconLogout2 } from "@tabler/icons-react";
import { Flex } from "@radix-ui/themes";

interface NavLinkProps {
  href: string;
  page: string;
  active: boolean;
  hero: any;
  name: string;
}

const NavLink = ({ href, active, hero, name }: NavLinkProps) => (
  <Flex direction="column" className={`overlay-wrap ${active ? "active": ""}`} style={{height: "5vh", width: "18vw"}}>
    <Link href={href} aria-label={name}>
      <NavigationShape className={`overlay-img-mobile ${active ? "active" : ""}`}/>
      <Flex className="overlay" py="2" pl="5.5vw">
          {hero}
      </Flex>
    </Link>
  </Flex>
);
  
interface MobileSidebarProps {
  page: string;
}

const NavigationShape = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    {...props}
    viewBox="0 0 100 100"
    width="100%"
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M100.625 0.365199C89.8814 2.30115 81.7307 11.6986 81.7307 22.9998V38.9998C81.7307 51.7024 71.4333 61.9998 58.7307 61.9998H40.3076C27.6051 61.9998 17.3076 51.7024 17.3076 38.9998V18.7498C17.3076 8.87979 9.68138 0.790973 0 0.0544598V0L100.625 1.24794e-06V0.365199Z"
      clipRule="evenodd"
    />
  </svg>
)

const MobileSidebar = ({ page }: MobileSidebarProps) => {
  const { mutateUser } = useUser();

  const { checkRoles } = useUser();
  const links = sidebarLinks(checkRoles).filter((link) => link);
  return (
    <Flex width="100%" className="layout mobile">
      <Flex width="100%" direction="row" px="4" pb="4" align="center" justify="between">
          {links.map((link) => (
            <NavLink
              active={page === link.page}
              key={link.page}
              href={link.href}
              hero ={link.hero}
              page={link.page}
              name={link.title}
            />
          ))}
          <Flex pt="1">
            <IconLogout2
            className="icon-dark"
            onClick={logout(mutateUser)}/>  
          </Flex>      
      </Flex>
    </Flex>
  );
};

export default MobileSidebar;
