"use client";

import { Flex, Heading } from "@radix-ui/themes";
import MobileLayout from "./MobileLayout";
import Sidebar from "./Sidebar";
import useMobile from "@/lib/useMobile";
import Loader from "../CustomUiComponents/Loader";

export type PageType =
  | "campaign"
  | "artist"
  | "users"
  | "creator"
  | "agency"
  | "scraper-accounts"
  | "settings"
  | "dashboard"
  | "people"
  | "builder"


interface Props {
  children: React.ReactNode;
  page: PageType;
  title: string | React.ReactNode;
  builder?: boolean;
}
const Layout = ({ children, page, title, builder }: Props) => {
  const { isMobile } = useMobile()

  if (isMobile === null) {
    return (
        <Loader full={true} />
      )
    }

  return (
    <>
     {isMobile ?
        <MobileLayout page={page} title={title}>
          {children}
        </MobileLayout> :
        <DesktopLayout page={page} title={title} builder={builder}>
          {children}
        </DesktopLayout>
      }
    </>
  );
};

const DesktopLayout = ({ children, page, title, builder }: Props) => {
  return (
    <Flex>
      <Sidebar page={page} />
      <Flex direction="column" gap={builder ? "0":"3"} pr={builder ? "0":"5"} width="100%" ml="150px" style={{background: "var(--color-background)"}}>
        <Flex direction="row">
          <Flex width={builder ? "55%":"100%"}>
            <Heading size="8" mt="9">{title}</Heading>
          </Flex>
          {builder ? 
          <Flex width="45%" className="preview"></Flex> :
          <></>}
        </Flex>
        <main className={builder ? "" :"mr-4"}>{children}</main>
      </Flex>
    </Flex>
  );
};

export default Layout;
