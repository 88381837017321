import { useEffect, useCallback, useMemo, useRef } from "react";
import Router from "next/router";
import useSWR from "swr";
import fetchJson, { FetchError } from "./fetchJson";
import { redirectToAuth } from "supertokens-auth-react";
import _ from "lodash"; // Import lodash for deep comparison

interface User {
  id: number;
  email: string;
  roles: string[];
  agencyId?: string;
  avatar?: string;
  campaignView?: string;
  viewableCampaigns: number[];
}

interface Props {
  shouldRedirect?: boolean;
}

export default function useUser(
  { shouldRedirect }: Props = {
    shouldRedirect: true,
  },
) {
  const { data: user, mutate: mutateUser, isLoading, isValidating, error } = useSWR<User>(`/auth/user`, fetchJson);

  // Use useRef to store the previous user value
  const prevUserRef = useRef<User | undefined>();

  // Memoize the user object with deep comparison
  const stableUser = useMemo(() => {
    if (!_.isEqual(user, prevUserRef.current)) {
      prevUserRef.current = user;
    }
    return prevUserRef.current;
  }, [user]);

  const stableShouldRedirect = useMemo(() => shouldRedirect, [shouldRedirect]);

  useEffect(() => {
    if (error && stableShouldRedirect && !isValidating) {
      if (error instanceof FetchError) {
        redirectToAuth({ redirectBack: true });
        return;
      }
    }

    if (error?.message === "access-code") {
      Router.push("/access");
      return;
    }

    if (error?.message === "payment-required") {
      Router.replace("/payment");
      return;
    }
  }, [stableUser, error, isLoading, isValidating, stableShouldRedirect]);

  const checkRoles = useCallback(
    (roles: string[]) => {
      if (!stableUser) return false;
      return roles.some((role) => stableUser?.roles.includes(role));
    },
    [stableUser]
  );

  return { user: stableUser, mutateUser, isLoading, error, checkRoles };
}
